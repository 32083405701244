import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const claimsRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/claims/views/ClaimsView.vue'),
		name: ROUTE_NAME.CLAIMS.INDEX,
		path: '/claims',
		beforeEnter: permissionMiddleware([Permission.CLAIM_READ]),
	},
	{
		component: async () => import('@/modules/claims/views/ClaimsDetailView.vue'),
		name: ROUTE_NAME.CLAIMS.DETAIL.INDEX,
		path: '/claims/:uuid',
		beforeEnter: permissionMiddleware([Permission.CLAIM_READ]),
	},
	{
		component: async () => import('@/modules/claims/views/ClaimsConceptView.vue'),
		name: ROUTE_NAME.CLAIMS.CONCEPT.INDEX,
		path: '/claims/concept/:uuid',
		beforeEnter: permissionMiddleware([Permission.CLAIM_CREATE]),
	},
]
