import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const accidentsRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/accidents/views/AccidentOverviewView.vue'),
		name: ROUTE_NAME.ACCIDENTS.INDEX,
		path: '/accidents',
		beforeEnter: permissionMiddleware([Permission.ACCIDENT_READ]),
	},
	{
		children: [
			{
				component: async () => import('@/modules/accidents/views/details/AccidentDetailGeneralView.vue'),
				name: ROUTE_NAME.ACCIDENTS.DETAILS.GENERAL,
				path: 'general',
			},
			{
				component: async () => import('@/modules/accidents/views/details/AccidentDetailAccidentAndInjuryView.vue'),
				name: ROUTE_NAME.ACCIDENTS.DETAILS.ACCIDENT,
				path: 'accident-and-injury',
			},
			{
				component: async () => import('@/modules/accidents/views/details/AccidentDetailActivityView.vue'),
				name: ROUTE_NAME.ACCIDENTS.DETAILS.ACTIVITY,
				path: 'activity',
			},
		],
		component: async () => import('@/modules/accidents/views/AccidentDetailView.vue'),
		name: ROUTE_NAME.ACCIDENTS.DETAILS.INDEX,
		path: '/accidents/:uuid',
		redirect: { name: ROUTE_NAME.ACCIDENTS.DETAILS.GENERAL },
		beforeEnter: permissionMiddleware([Permission.ACCIDENT_READ]),
	},
	{
		component: async () => import('@/modules/accidents/views/AccidentReportView.vue'),
		name: ROUTE_NAME.ACCIDENTS.REPORT,
		path: '/accidents/:uuid/report',
	},
]
