import type { Component } from 'vue'

import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

export enum Icon {
	ADD_TIME = 'addTime',
	ACCIDENTS = 'accidents',
	ALARM_CLOCK = 'alarm-clock',
	CORNER_DOWN_LEFT = 'corner-down-left',
	ADD_DATE = 'add-date',
	ALLERGY = 'allergy',
	ANOMALY = 'anomaly',
	REFERRAL_PREMIUM = 'application-premium',
	ARROW_DOWN = 'arrow-down',
	ARROW_LEFT = 'arrow-left',
	ARROW_RIGHT = 'arrow-right',
	ARROW_UP = 'arrow-up',
	ASSIGN = 'assign',
	ATTACHMENT_FALSE = 'attachment-false',
	ATTACHMENT_TRUE = 'attachment-true',
	BANDAGE = 'bandage',
	BIKE = 'bike',
	BIRD = 'bird',
	BLOCK = 'block',
	BOOKMARK = 'bookmark',
	BORDEREL = 'borderel',
	BRIEFCASE = 'briefcase',
	BURGER_MENU = 'burger-menu',
	BUS = 'bus',
	CALCULATOR = 'calculator',
	CALENDAR = 'calendar',
	CALENDAR_RECURRENT = 'calendar_recurrent',
	CALENDAR_SINGLE = 'calendar_single',
	CALL = 'call',
	CAMPAIGN = 'campaign',
	CAR_FRONT = 'car-front',
	CAR_SIDE = 'car-side',
	CAT = 'cat',
	CHAT_BUBBLE_ALERT = 'chat-bubble-alert',
	CHAT_BUBBLE = 'chat-bubble',
	CHECKMARK_BOLD = 'checkmark-bold',
	CHECKMARK_CIRCLE = 'checkmark-circle',
	CHECKMARK_FILL = 'checkmark-fill',
	CHECK_MARK = 'checkmark',
	CHECKMARK_SMALL = 'checkmark-small',
	CHORES = 'chores',
	CIRCLE_DASHED = 'circle-dashed',
	CLAIMS = 'claims',
	CLEANING = 'cleaning',
	CLOCK = 'clock',
	CLOSE_BOLD = 'close-bold',
	CLOSE = 'close',
	CLOTHING = 'clothing',
	COMMENT = 'comment',
	CONE = 'cone',
	CONSULTANT = 'consultant',
	CONVERT = 'convert',
	COPY = 'copy',
	CREATOR_ICON = 'creator-icon',
	CREDIT_CARD = 'credit-card',
	CROSS_SMALL = 'cross-small',
	CUSTOMERS = 'customers',
	DASHBOARD = 'dashboard',
	DEBTORS = 'debtors',
	DELETE = 'delete',
	DOCTOR = 'doctor',
	DOG = 'dog',
	DOTS_HORIZONTAL = 'dots-horizontal',
	DOTS = 'dots',
	DOTS_VERTICAL = 'dots-vertical',
	DOUBLE_CHECKMARK_FULL = 'double-checkmark-full',
	DOUBLE_CHECKMARK_HALF = 'double-checkmark-half',
	DOUBLE_CHECKMARK_NONE = 'double-checkmark-none',
	DOWNLOAD = 'download',
	EDIT_DATE = 'edit-date',
	EDIT = 'edit',
	EDITED = 'edited',
	ELECTRONIC_CHECKS = 'electronic-checks',
	EYE = 'eye',
	PAPER_CHECKS = 'paper-checks',
	LETTER_P = 'letter-p',
	LETTER_E = 'letter-e',
	LINK = 'link',
	LOCK_ICON_OPEN = 'lock-icon-open',
	LOCK_ICON_CLOSED = 'lock-icon-closed',
	EMPLOYEE = 'employee',
	ENROLLMENT_APPLICATION = 'enrollment-application',
	FAMILY = 'family',
	FILTER = 'filter',
	FLAG = 'flag',
	FORMAT_BOLD = 'format-bold',
	FORMAT_ITALIC = 'format-italic',
	FORMAT_IMAGE = 'format-image',
	FORMAT_UNDERLINE = 'format-underline',
	FORMAT_HEADING_ONE = 'format-heading-one',
	FORMAT_HEADING_TWO = 'format-heading-two',
	FORMAT_UNORDERED_LIST = 'format-unordered-list',
	FORMAT_ORDERED_LIST = 'format-ordered-list',
	FORMAT_BLOCK_QUOTE = 'format-block-quote',
	FORMAT_LINK = 'format-link',
	FOLDER = 'folder',
	GENDER_FEMALE = 'gender-female',
	GENDER_MALE = 'gender-male',
	GRADUATE_CAP = 'graduate-cap',
	HAMSTER = 'hamster',
	HAND_CLEANING = 'hand-cleaning',
	HAND_INSTALLMENT = 'hand-installment',
	HAND_PREMIUM = 'hand-premium',
	SHIELD_CASH = 'shield-cash',
	SHIELD_CROSS = 'shield-cross',
	HAND = 'hand',
	HASHTAG = 'hashtag',
	SCALES = 'scales',
	HEART = 'heart',
	HISTORY = 'history',
	HOME_MAID_LOGO = 'home-maid-logo',
	HOUSE = 'house',
	IMAGE = 'image',
	INCOMING_MAIL = 'incoming-mail',
	INFO = 'info',
	IRONING = 'ironing',
	LEGAL = 'legal',
	LOGBOOK = 'logbook',
	LOCATION_PIN = 'location-pin',
	MAIL_ALERT = 'mail-alert',
	MAIL = 'mail',
	MATCHING = 'matching',
	MERGE = 'merge',
	MINUS_BOLD = 'minus-bold',
	MINUS = 'minus',
	MOTORCYCLE = 'motorcycle',
	MUTUALITY = 'mutuality',
	NEWS_ITEMS = 'news-items',
	NOTE = 'note',
	NOTIFICATION_ALERT = 'notification-alert',
	NOTIFICATION = 'notification',
	OFFICE = 'office',
	OPEN_TAB = 'open-tab',
	OUTGOING_MAIL = 'outgoing-mail',
	PACIFIER = 'pacifier',
	PARTTIME = 'parttime',
	PAW = 'paw',
	PHONE = 'phone',
	PIN_FALSE = 'state-false',
	PIN_TRUE = 'state-true',
	PLUS_BOLD = 'plus-bold',
	PLUS = 'plus',
	PLUXEE = 'pluxee',
	POWER_OUT = 'power-out',
	PRINT = 'print',
	QUESTION_MARK = 'question-mark',
	REDO = 'redo',
	REFERRAL = 'referral',
	REMUNERATION = 'remuneration',
	SEARCH = 'search',
	SETTINGS = 'settings',
	SHARE = 'share',
	SIGN = 'sign',
	SIGN_POST = 'sign-post',
	THUMBS_UP = 'thumbs-up',
	THUMBS_DOWN = 'thumbs-down',
	SPARKLE = 'sparkle',
	STAR = 'star',
	STAR_RATING_FALSE = 'star-rating-false',
	STAR_RATING_TRUE = 'star-rating-true',
	SUBSIDIES = 'subsidies',
	SWITCH = 'switch',
	TODO_ALERT = 'todo-alert',
	TODO = 'todo',
	TRANSPORT_EXPENSE = 'transport-expense',
	TRANSPORT = 'transport',
	TREND_UP_ARROW = 'trend-up-arrow',
	TRIXXA_LOGO = 'trixxa-logo',
	UNDO_DELETE = 'undo-delete',
	UNLINK = 'unlink',
	UPLOAD = 'upload',
	USER = 'user',
	VIEW_GRID = 'view-grid',
	VIEW_LIST = 'view-list',
	WAGE_GARNISHMENT = 'wage-garnishment',
	WALKING = 'walking',
	WALLET = 'wallet',
	WARNING_CIRCLE_FILL = 'warning-circle-fill',
	WARNING_CIRCLE = 'warning-circle',
	WARNING_TRIANGLE = 'warning-triangle',
	WARNING_TRIANGLE_BOLD = 'warning-circle-bold',
	WITNESS = 'witness',
	ZIP = 'zip',
	LOADING = 'loading',
	CHAT = 'chat',
	CHAT_APP = 'chat-app',
	MARKER = 'marker',
}

const icons = new Map<Icon, { color: DefaultThemeColor; icon: Promise<Component> }>([
	[Icon.ALARM_CLOCK, { color: 'lima', icon: import('@/icons/AlarmClockIcon.vue') }],
	[Icon.SHIELD_CASH, { color: 'lima', icon: import('@/icons/ShieldCashIcon.vue') }],
	[Icon.SHIELD_CROSS, { color: 'lima', icon: import('@/icons/ShieldCrossIcon.vue') }],
	[Icon.SCALES, { color: 'lima', icon: import('@/icons/ScaleIcon.vue') }],
	[Icon.HAND, { color: 'lima', icon: import('@/icons/HandIcon.vue') }],
	[Icon.PLUS_BOLD, { color: 'lima', icon: import('@/icons/PlusBoldIcon.vue') }],
	[Icon.ADD_TIME, { color: 'lima', icon: import('@/icons/AddDateIcon.vue') }],
	[Icon.ACCIDENTS, { color: 'lima', icon: import('@/icons/AccidentsIcon.vue') }],
	[Icon.ADD_DATE, { color: 'lima', icon: import('@/icons/AddDateIcon.vue') }],
	[Icon.ALLERGY, { color: 'lima', icon: import('@/icons/AllergyIcon.vue') }],
	[Icon.ANOMALY, { color: 'lima', icon: import('@/icons/AnomalyIcon.vue') }],
	[Icon.REFERRAL_PREMIUM, { color: 'lima', icon: import('@/icons/ApplicationPremiumIcon.vue') }],
	[Icon.ARROW_DOWN, { color: 'lima', icon: import('@/icons/ArrowDownIcon.vue') }],
	[Icon.ARROW_LEFT, { color: 'lima', icon: import('@/icons/ArrowLeftIcon.vue') }],
	[Icon.ARROW_RIGHT, { color: 'lima', icon: import('@/icons/ArrowRightIcon.vue') }],
	[Icon.ARROW_UP, { color: 'lima', icon: import('@/icons/ArrowUpIcon.vue') }],
	[Icon.ASSIGN, { color: 'lima', icon: import('@/icons/AssignIcon.vue') }],
	[Icon.ATTACHMENT_FALSE, { color: 'lima', icon: import('@/icons/AttachmentFalseIcon.vue') }],
	[Icon.ATTACHMENT_TRUE, { color: 'lima', icon: import('@/icons/AttachmentTrueIcon.vue') }],
	[Icon.BANDAGE, { color: 'lima', icon: import('@/icons/BandageIcon.vue') }],
	[Icon.BIKE, { color: 'lima', icon: import('@/icons/BikeIcon.vue') }],
	[Icon.BIRD, { color: 'lima', icon: import('@/icons/BirdIcon.vue') }],
	[Icon.BLOCK, { color: 'lima', icon: import('@/icons/BlockIcon.vue') }],
	[Icon.BOOKMARK, { color: 'lima', icon: import('@/icons/BookmarkIcon.vue') }],
	[Icon.BORDEREL, { color: 'lima', icon: import('@/icons/BorderelIcon.vue') }],
	[Icon.BRIEFCASE, { color: 'lima', icon: import('@/icons/BriefcaseIcon.vue') }],
	[Icon.BURGER_MENU, { color: 'lima', icon: import('@/icons/BurgerMenuIcon.vue') }],
	[Icon.BUS, { color: 'lima', icon: import('@/icons/BusIcon.vue') }],
	[Icon.THUMBS_DOWN, { color: 'lima', icon: import('@/icons/ThumbsDownIcon.vue') }],
	[Icon.THUMBS_UP, { color: 'lima', icon: import('@/icons/ThumbsUpIcon.vue') }],
	[Icon.CALCULATOR, { color: 'lima', icon: import('@/icons/CalculatorIcon.vue') }],
	[Icon.CALENDAR, { color: 'lima', icon: import('@/icons/CalendarIcon.vue') }],
	[Icon.CALENDAR_RECURRENT, { color: 'lima', icon: import('@/icons/CalendarRecurrentIcon.vue') }],
	[Icon.CALENDAR_SINGLE, { color: 'lima', icon: import('@/icons/CalendarSingleIcon.vue') }],
	[Icon.CALL, { color: 'lima', icon: import('@/icons/CallIcon.vue') }],
	[Icon.CAMPAIGN, { color: 'lima', icon: import('@/icons/CampaignIcon.vue') }],
	[Icon.CAR_FRONT, { color: 'safety-orange', icon: import('@/icons/CarFrontIcon.vue') }],
	[Icon.CAR_SIDE, { color: 'lima', icon: import('@/icons/CarSideIcon.vue') }],
	[Icon.CAT, { color: 'lima', icon: import('@/icons/CatIcon.vue') }],
	[Icon.CHAT_BUBBLE_ALERT, { color: 'lima', icon: import('@/icons/ChatBubbleAlertIcon.vue') }],
	[Icon.CHAT_BUBBLE, { color: 'lima', icon: import('@/icons/ChatBubbleIcon.vue') }],
	[Icon.CHECKMARK_FILL, { color: 'lima', icon: import('@/icons/CheckmarkFillIcon.vue') }],
	[Icon.CHECKMARK_CIRCLE, { color: 'lima', icon: import('@/icons/CheckmarkCircleIcon.vue') }],
	[Icon.CORNER_DOWN_LEFT, { color: 'waterloo', icon: import('@/icons/CornerDownLeftIcon.vue') }],
	[Icon.CHECK_MARK, { color: 'lima', icon: import('@/icons/CheckmarkIcon.vue') }],
	[Icon.CHECKMARK_CIRCLE, { color: 'lima', icon: import('@/icons/CheckmarkCircleIcon.vue') }],
	[Icon.CHECKMARK_SMALL, { color: 'lima', icon: import('@/icons/CheckmarkSmallIcon.vue') }],
	[Icon.CHORES, { color: 'schoolbus-yellow', icon: import('@/icons/ChoresIcon.vue') }],
	[Icon.CIRCLE_DASHED, { color: 'lima', icon: import('@/icons/CircleDashedIcon.vue') }],
	[Icon.CLAIMS, { color: 'lima', icon: import('@/icons/ClaimsIcon.vue') }],
	[Icon.CLEANING, { color: 'lima', icon: import('@/icons/CleaningIcon.vue') }],
	[Icon.CLOCK, { color: 'crayon-blue', icon: import('@/icons/ClockIcon.vue') }],
	[Icon.CLOSE_BOLD, { color: 'safety-orange', icon: import('@/icons/CloseBoldIcon.vue') }],
	[Icon.CLOSE, { color: 'lima', icon: import('@/icons/CloseIcon.vue') }],
	[Icon.CLOTHING, { color: 'lima', icon: import('@/icons/ClothingIcon.vue') }],
	[Icon.COMMENT, { color: 'lima', icon: import('@/icons/CommentIcon.vue') }],
	[Icon.CONE, { color: 'lima', icon: import('@/icons/ConeIcon.vue') }],
	[Icon.CONSULTANT, { color: 'crayon-blue', icon: import('@/icons/ConsultantIcon.vue') }],
	[Icon.CONVERT, { color: 'lima', icon: import('@/icons/ConvertIcon.vue') }],
	[Icon.COPY, { color: 'lima', icon: import('@/icons/CopyIcon.vue') }],
	[Icon.CORNER_DOWN_LEFT, { color: 'waterloo', icon: import('@/icons/CornerDownLeftIcon.vue') }],
	[Icon.CREATOR_ICON, { color: 'lima', icon: import('@/icons/CreatorIcon.vue') }],
	[Icon.CREDIT_CARD, { color: 'lima', icon: import('@/icons/CreditCardIcon.vue') }],
	[Icon.CROSS_SMALL, { color: 'lima', icon: import('@/icons/CrossSmallIcon.vue') }],
	[Icon.CUSTOMERS, { color: 'lima', icon: import('@/icons/CustomersIcon.vue') }],
	[Icon.DASHBOARD, { color: 'lima', icon: import('@/icons/DashboardIcon.vue') }],
	[Icon.DEBTORS, { color: 'lima', icon: import('@/icons/DebtorsIcon.vue') }],
	[Icon.DELETE, { color: 'safety-orange', icon: import('@/icons/DeleteIcon.vue') }],
	[Icon.DOCTOR, { color: 'lima', icon: import('@/icons/DoctorIcon.vue') }],
	[Icon.DOG, { color: 'lima', icon: import('@/icons/DogIcon.vue') }],
	[Icon.DOTS_HORIZONTAL, { color: 'waterloo', icon: import('@/icons/DotsHorizontalIcon.vue') }],
	[Icon.DOTS, { color: 'lima', icon: import('@/icons/DotsIcon.vue') }],
	[Icon.DOTS_VERTICAL, { color: 'lima', icon: import('@/icons/DotsVerticalIcon.vue') }],
	[Icon.DOUBLE_CHECKMARK_FULL, { color: 'lima', icon: import('@/icons/DoubleCheckmarkFullIcon.vue') }],
	[Icon.DOUBLE_CHECKMARK_HALF, { color: 'lima', icon: import('@/icons/DoubleCheckmarkHalfIcon.vue') }],
	[Icon.DOUBLE_CHECKMARK_NONE, { color: 'lima', icon: import('@/icons/DoubleCheckmarkNoneIcon.vue') }],
	[Icon.DOWNLOAD, { color: 'lima', icon: import('@/icons/DownloadIcon.vue') }],
	[Icon.EDIT_DATE, { color: 'lima', icon: import('@/icons/EditDateIcon.vue') }],
	[Icon.EDIT, { color: 'crayon-blue', icon: import('@/icons/EditIcon.vue') }],
	[Icon.EDITED, { color: 'crayon-blue', icon: import('@/icons/EditedIcon.vue') }],
	[Icon.ELECTRONIC_CHECKS, { color: 'crayon-blue', icon: import('@/icons/ElectronicChecksIcon.vue') }],
	[Icon.EYE, { color: 'lima', icon: import('@/icons/EyeIcon.vue') }],
	[Icon.PAPER_CHECKS, { color: 'crayon-blue', icon: import('@/icons/PaperChecksIcon.vue') }],
	[Icon.LETTER_E, { color: 'lima', icon: import('@/icons/LetterEIcon.vue') }],
	[Icon.LETTER_P, { color: 'lima', icon: import('@/icons/LetterPIcon.vue') }],
	[Icon.LINK, { color: 'lima', icon: import('@/icons/LinkIcon.vue') }],
	[Icon.LOCATION_PIN, { color: 'lima', icon: import('@/icons/LocationPinIcon.vue') }],
	[Icon.LOCK_ICON_OPEN, { color: 'lima', icon: import('@/icons/LockOpenIcon.vue') }],
	[Icon.LOCK_ICON_CLOSED, { color: 'lima', icon: import('@/icons/LockClosedIcon.vue') }],
	[Icon.EMPLOYEE, { color: 'crayon-blue', icon: import('@/icons/EmployeesIcon.vue') }],
	[Icon.ENROLLMENT_APPLICATION, { color: 'lima', icon: import('@/icons/EnrollmentApplicationIcon.vue') }],
	[Icon.FAMILY, { color: 'lima', icon: import('@/icons/FamilyIcon.vue') }],
	[Icon.FILTER, { color: 'lima', icon: import('@/icons/FilterIcon.vue') }],
	[Icon.FLAG, { color: 'lima', icon: import('@/icons/FlagIcon.vue') }],
	[Icon.FOLDER, { color: 'crayon-blue', icon: import('@/icons/FolderIcon.vue') }],
	[Icon.FORMAT_BOLD, { color: 'waterloo', icon: import('@/icons/FormatBoldIcon.vue') }],
	[Icon.FORMAT_ITALIC, { color: 'waterloo', icon: import('@/icons/FormatItalicIcon.vue') }],
	[Icon.FORMAT_IMAGE, { color: 'waterloo', icon: import('@/icons/FormatImageIcon.vue') }],
	[Icon.FORMAT_UNDERLINE, { color: 'waterloo', icon: import('@/icons/FormatUnderlineIcon.vue') }],
	[Icon.FORMAT_HEADING_ONE, { color: 'waterloo', icon: import('@/icons/FormatHeadingOneIcon.vue') }],
	[Icon.FORMAT_HEADING_TWO, { color: 'waterloo', icon: import('@/icons/FormatHeadingTwoIcon.vue') }],
	[Icon.FORMAT_UNORDERED_LIST, { color: 'waterloo', icon: import('@/icons/FormatUnorderedListIcon.vue') }],
	[Icon.FORMAT_ORDERED_LIST, { color: 'waterloo', icon: import('@/icons/FormatOrderedListIcon.vue') }],
	[Icon.FORMAT_BLOCK_QUOTE, { color: 'waterloo', icon: import('@/icons/FormatBlockQuoteIcon.vue') }],
	[Icon.FORMAT_LINK, { color: 'waterloo', icon: import('@/icons/FormatLinkIcon.vue') }],
	[Icon.GENDER_FEMALE, { color: 'lima', icon: import('@/icons/GenderFemaleIcon.vue') }],
	[Icon.GENDER_MALE, { color: 'lima', icon: import('@/icons/GenderMaleIcon.vue') }],
	[Icon.GRADUATE_CAP, { color: 'lima', icon: import('@/icons/GraduateCapIcon.vue') }],
	[Icon.HAMSTER, { color: 'lima', icon: import('@/icons/HamsterIcon.vue') }],
	[Icon.HAND_CLEANING, { color: 'lima', icon: import('@/icons/HandCleaningIcon.vue') }],
	[Icon.HAND_INSTALLMENT, { color: 'lima', icon: import('@/icons/HandInstallmentIcon.vue') }],
	[Icon.HAND_PREMIUM, { color: 'lima', icon: import('@/icons/HandPremiumIcon.vue') }],
	[Icon.HASHTAG, { color: 'waterloo', icon: import('@/icons/HashtagIcon.vue') }],
	[Icon.HEART, { color: 'lima', icon: import('@/icons/HeartIcon.vue') }],
	[Icon.HISTORY, { color: 'lima', icon: import('@/icons/HistoryIcon.vue') }],
	[Icon.HOME_MAID_LOGO, { color: 'lima', icon: import('@/icons/HomeMaidLogoIcon.vue') }],
	[Icon.HOUSE, { color: 'lima', icon: import('@/icons/HouseIcon.vue') }],
	[Icon.IMAGE, { color: 'lima', icon: import('@/icons/ImageIcon.vue') }],
	[Icon.INCOMING_MAIL, { color: 'lima', icon: import('@/icons/IncomingMailIcon.vue') }],
	[Icon.INFO, { color: 'lima', icon: import('@/icons/InfoIcon.vue') }],
	[Icon.IRONING, { color: 'lavender-indigo', icon: import('@/icons/IroningIcon.vue') }],
	[Icon.LEGAL, { color: 'lima', icon: import('@/icons/LegalIcon.vue') }],
	[Icon.LOGBOOK, { color: 'lima', icon: import('@/icons/LogbookIcon.vue') }],
	[Icon.MAIL_ALERT, { color: 'lavender-indigo', icon: import('@/icons/MailAlertIcon.vue') }],
	[Icon.MAIL, { color: 'lavender-indigo', icon: import('@/icons/MailIcon.vue') }],
	[Icon.MATCHING, { color: 'lima', icon: import('@/icons/MatchingIcon.vue') }],
	[Icon.MERGE, { color: 'lima', icon: import('@/icons/MergeIcon.vue') }],
	[Icon.MINUS_BOLD, { color: 'lima', icon: import('@/icons/MinusBoldIcon.vue') }],
	[Icon.MINUS, { color: 'lima', icon: import('@/icons/MinusIcon.vue') }],
	[Icon.MOTORCYCLE, { color: 'lima', icon: import('@/icons/MotorcycleIcon.vue') }],
	[Icon.MUTUALITY, { color: 'lima', icon: import('@/icons/MutualityIcon.vue') }],
	[Icon.NEWS_ITEMS, { color: 'lima', icon: import('@/icons/NewsItemsIcon.vue') }],
	[Icon.NOTE, { color: 'lima', icon: import('@/icons/NoteIcon.vue') }],
	[Icon.NOTIFICATION_ALERT, { color: 'lima', icon: import('@/icons/NotificationAlertIcon.vue') }],
	[Icon.NOTIFICATION, { color: 'lima', icon: import('@/icons/NotificationIcon.vue') }],
	[Icon.OFFICE, { color: 'lima', icon: import('@/icons/OfficeIcon.vue') }],
	[Icon.OPEN_TAB, { color: 'lima', icon: import('@/icons/OpenTabIcon.vue') }],
	[Icon.OUTGOING_MAIL, { color: 'lima', icon: import('@/icons/OutgoingMailIcon.vue') }],
	[Icon.PACIFIER, { color: 'lima', icon: import('@/icons/PacifierIcon.vue') }],
	[Icon.PARTTIME, { color: 'lima', icon: import('@/icons/ParttimeIcon.vue') }],
	[Icon.PAW, { color: 'lima', icon: import('@/icons/PawIcon.vue') }],
	[Icon.PHONE, { color: 'safety-orange', icon: import('@/icons/PhoneIcon.vue') }],
	[Icon.PIN_FALSE, { color: 'lima', icon: import('@/icons/PinFalseIcon.vue') }],
	[Icon.PIN_TRUE, { color: 'lima', icon: import('@/icons/PinTrueIcon.vue') }],
	[Icon.PLUS_BOLD, { color: 'lima', icon: import('@/icons/PlusBoldIcon.vue') }],
	[Icon.PLUS, { color: 'lima', icon: import('@/icons/PlusIcon.vue') }],
	[Icon.PLUXEE, { color: 'lima', icon: import('@/icons/PluxeeIcon.vue') }],
	[Icon.POWER_OUT, { color: 'lima', icon: import('@/icons/PowerOutIcon.vue') }],
	[Icon.PRINT, { color: 'lima', icon: import('@/icons/PrintIcon.vue') }],
	[Icon.QUESTION_MARK, { color: 'lima', icon: import('@/icons/QuestionMarkIcon.vue') }],
	[Icon.REDO, { color: 'lima', icon: import('@/icons/RedoIcon.vue') }],
	[Icon.REFERRAL, { color: 'lima', icon: import('@/icons/ReferralIcon.vue') }],
	[Icon.REMUNERATION, { color: 'lima', icon: import('@/icons/RemunerationIcon.vue') }],
	[Icon.SEARCH, { color: 'lima', icon: import('@/icons/SearchIcon.vue') }],
	[Icon.SETTINGS, { color: 'lima', icon: import('@/icons/SettingsIcon.vue') }],
	[Icon.SHARE, { color: 'crayon-blue', icon: import('@/icons/ShareIcon.vue') }],
	[Icon.SIGN, { color: 'crayon-blue', icon: import('@/icons/SignIcon.vue') }],
	[Icon.SIGN_POST, { color: 'lima', icon: import('@/icons/SignPostIcon.vue') }],
	[Icon.SPARKLE, { color: 'lima', icon: import('@/icons/SparkleIcon.vue') }],
	[Icon.STAR, { color: 'lima', icon: import('@/icons/StarIcon.vue') }],
	[Icon.STAR_RATING_FALSE, { color: 'lima', icon: import('@/icons/StarRatingFalseIcon.vue') }],
	[Icon.STAR_RATING_TRUE, { color: 'lima', icon: import('@/icons/StarRatingTrueIcon.vue') }],
	[Icon.SUBSIDIES, { color: 'lima', icon: import('@/icons/SubsidiesIcon.vue') }],
	[Icon.SWITCH, { color: 'lima', icon: import('@/icons/SwitchIcon.vue') }],
	[Icon.TODO_ALERT, { color: 'lima', icon: import('@/icons/TodoAlertIcon.vue') }],
	[Icon.TODO, { color: 'lima', icon: import('@/icons/TodoIcon.vue') }],
	[
		Icon.TRANSPORT_EXPENSE,
		{
			color: 'safety-orange',
			icon: import('@/icons/TransportExpenseIcon.vue'),
		},
	],
	[Icon.TRANSPORT, { color: 'lima', icon: import('@/icons/TransportIcon.vue') }],
	[Icon.TREND_UP_ARROW, { color: 'lima', icon: import('@/icons/TrendUpArrowIcon.vue') }],
	[Icon.TRIXXA_LOGO, { color: 'lima', icon: import('@/icons/TrixxaLogoIcon.vue') }],
	[Icon.UNDO_DELETE, { color: 'lima', icon: import('@/icons/UndoDeleteIcon.vue') }],
	[Icon.UNLINK, { color: 'lima', icon: import('@/icons/UnlinkIcon.vue') }],
	[Icon.UPLOAD, { color: 'lima', icon: import('@/icons/UploadIcon.vue') }],
	[Icon.USER, { color: 'lima', icon: import('@/icons/UserIcon.vue') }],
	[Icon.VIEW_GRID, { color: 'lima', icon: import('@/icons/ViewGridIcon.vue') }],
	[Icon.VIEW_LIST, { color: 'lima', icon: import('@/icons/ViewListIcon.vue') }],
	[Icon.WAGE_GARNISHMENT, { color: 'lima', icon: import('@/icons/WageGarnishmentIcon.vue') }],
	[Icon.WALKING, { color: 'lima', icon: import('@/icons/WalkingIcon.vue') }],
	[Icon.WALLET, { color: 'lima', icon: import('@/icons/WalletIcon.vue') }],
	[Icon.WARNING_CIRCLE_FILL, { color: 'lima', icon: import('@/icons/WarningCircleFillIcon.vue') }],
	[Icon.WARNING_CIRCLE, { color: 'lima', icon: import('@/icons/WarningCircleIcon.vue') }],
	[Icon.WARNING_TRIANGLE, { color: 'lima', icon: import('@/icons/WarningTriangleIcon.vue') }],
	[Icon.WARNING_TRIANGLE_BOLD, { color: 'vivid-red', icon: import('@/icons/WarningTriangleBoldIcon.vue') }],
	[Icon.WITNESS, { color: 'lima', icon: import('@/icons/WitnessIcon.vue') }],
	[Icon.ZIP, { color: 'lima', icon: import('@/icons/ZipIcon.vue') }],
	[Icon.LOADING, { color: 'lima', icon: import('@/icons/LoadingIcon.vue') }],
	[Icon.CHAT, { color: 'crayon-blue', icon: import('@/icons/ChatIcon.vue') }],
	[Icon.CHAT_APP, { color: 'crayon-blue', icon: import('@/icons/ChatAppIcon.vue') }],
	[Icon.MARKER, { color: 'lima', icon: import('@/icons/MarkerIcon.vue') }],
])

export async function getIconComponent(icon: Icon | null | undefined): Promise<Component> {
	if (!icon) {
		return import('@/icons/QuestionMarkIcon.vue')
	}

	const iconComponent = icons.get(icon)

	return iconComponent?.icon || import('@/icons/QuestionMarkIcon.vue')
}

export function getIconColor(icon: Icon | null | undefined): DefaultThemeColor | null {
	if (!icon) {
		return null
	}

	return icons.get(icon)?.color || null
}
