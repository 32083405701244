import { QueryKey } from '@/models/queryKey.enum'
import type { QueryResult } from '@/models/queryResult.type'
import { useQueryListResult } from '@/models/queryResult.type'
import type { InfoBubbleItem } from '@/modules/settings/models/info-bubbles/infoBubble.type'
import InfoBubbleService from '@/modules/settings/services/infoBubble.service'

const infoBubbleService = new InfoBubbleService()

const HOUR_STALE_TIME = 1000 * 60 * 60

export function useInfoBubbleQuery(): QueryResult<InfoBubbleItem[]> {
	return useQueryListResult<InfoBubbleItem>({
		staleTime: HOUR_STALE_TIME,
		queryKey: [QueryKey.INFO_BUBBLES],
		queryFn: infoBubbleService.getAll,
	})
}
