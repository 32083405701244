import { useDebounceFn } from '@vueuse/core'
import type { AxiosInstance } from 'axios'
import axios from 'axios'

import { useToastMessages } from '@/composables/toastMessages.composable'
import type { ApiError } from '@/constants/apiError.enum'
import { API_BASE_URL } from '@/constants/environment.constant'
import { oAuthClient } from '@/http/oAuthClient'
import { i18nPlugin } from '@/plugins/i18n.plugin'

const ERR_NETWORK = 'ERR_NETWORK'
const HTTP_BAD_RESPONSE = 503
const EMPTY_ERROR = ''

export interface AxiosApiError {
	config: {
		baseURL: string
		url: string
		method: string
		data: string
	}
	response: {
		status: number
		data: AxiosApiError500ResponseData | AxiosApiErrorResponseData
	}
}

interface AxiosApiError500ResponseData {
	code: string
	detail: string
	id: string
}

export interface AxiosApiErrorResponseError {
	code: ApiError
	detail: string
	meta?: {
		fields: string[]
		messages: string[]
		rowIndex: number
	}
}

export interface AxiosApiErrorResponseData {
	errors: AxiosApiErrorResponseError[]
}

export const httpClient: AxiosInstance = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json;charset=UTF-8',
	},
})

httpClient.interceptors.request.use((config) => {
	try {
		return oAuthClient.addAuthorizationHeader(config)
	} catch (error) {
		const toast = useToastMessages()
		toast.pushError(i18nPlugin.global.t('auth.login_error'))
		return config
	}
})

const throwDebouncedToastError = useDebounceFn((value: string) => {
	const toast = useToastMessages()
	toast.pushError(value)
}, 400)

httpClient.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === HTTP_BAD_RESPONSE) {
			return Promise.reject(error)
		}

		if (error?.code === ERR_NETWORK) {
			const errorMessage = i18nPlugin.global.t('auth.network_error')
			await throwDebouncedToastError(errorMessage)
			return EMPTY_ERROR
		}

		return Promise.reject(error)
	}
)

export function isAxiosApiErrorResponseData(value: unknown): value is AxiosApiErrorResponseData {
	return (value as AxiosApiErrorResponseData)?.errors !== undefined
}
