import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const matchingRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.MATCHING_CENTER_READ]),
		component: async () => import('@/modules/matching/views/MatchingView.vue'),
		name: ROUTE_NAME.MATCHING_CENTER.INDEX,
		path: '/matching-center',
		redirect: { name: ROUTE_NAME.MATCHING_CENTER.EMPLOYEES },
		children: [
			{
				component: async () => import('@/modules/matching/views/MatchingEmployeesView.vue'),
				name: ROUTE_NAME.MATCHING_CENTER.EMPLOYEES,
				path: 'employees',
			},
			{
				component: async () => import('@/modules/matching/views/MatchingCustomersView.vue'),
				name: ROUTE_NAME.MATCHING_CENTER.CUSTOMERS,
				path: 'customers',
			},
			{
				component: async () => import('@/modules/matching/views/MatchingReservationsView.vue'),
				name: ROUTE_NAME.MATCHING_CENTER.RESERVATIONS,
				path: 'reservations',
			},
		],
	},
]
