import { Permission } from '@/constants/permission.enum'
import { useAuthStore } from '@/modules/authentication/stores/auth.store'

interface HasPermission {
	hasPermission: (permissions: Permission[]) => boolean
}

export function usePermission(): HasPermission {
	function hasPermission(permissions: Permission[]): boolean {
		const authStore = useAuthStore()
		return authStore.hasPermission([...permissions, Permission.ADMIN])
	}

	return { hasPermission }
}
