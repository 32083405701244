import { usePrimeVue } from 'primevue/config'

import { i18nPlugin } from '@/plugins/i18n.plugin'

export function usePrimeVueConfig(): {
	setPrimeVueConfig: () => void
} {
	const { t } = i18nPlugin.global
	const primeVue = usePrimeVue()

	function setPrimeVueConfig(): void {
		if (primeVue.config.locale) {
			primeVue.config.locale = {
				...primeVue.config.locale,
				dayNamesMin: [
					t('prime_vue.days.sunday_short'),
					t('prime_vue.days.monday_short'),
					t('prime_vue.days.tuesday_short'),
					t('prime_vue.days.wednesday_short'),
					t('prime_vue.days.thursday_short'),
					t('prime_vue.days.friday_short'),
					t('prime_vue.days.saturday_short'),
				],
				emptyMessage: t('prime_vue.empty_message'),
				emptySearchMessage: t('prime_vue.empty_search_message'),
				firstDayOfWeek: 1,
				monthNames: [
					t('prime_vue.months.january'),
					t('prime_vue.months.february'),
					t('prime_vue.months.march'),
					t('prime_vue.months.april'),
					t('prime_vue.months.may'),
					t('prime_vue.months.june'),
					t('prime_vue.months.july'),
					t('prime_vue.months.august'),
					t('prime_vue.months.september'),
					t('prime_vue.months.october'),
					t('prime_vue.months.november'),
					t('prime_vue.months.december'),
				],
				today: t('prime_vue.today'),
			}
		}
	}

	return { setPrimeVueConfig }
}
