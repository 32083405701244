import { computed } from 'vue'

import type { DataItem } from '@/models/dataItem.type'
import { i18nPlugin } from '@/plugins/i18n.plugin'

export enum Language {
	NL = 'nl',
	FR = 'fr',
	DE = 'de',
	EN = 'en',
	OTHER = 'other',
}

export const LANGUAGE_OPTIONS = computed<DataItem<Language>[]>(() => {
	const { t } = i18nPlugin.global

	return [
		{
			label: t('shared.language_type.nl'),
			value: Language.NL,
		},
		{
			label: t('shared.language_type.fr'),
			value: Language.FR,
		},
		{
			label: t('shared.language_type.de'),
			value: Language.DE,
		},
		{
			label: t('shared.language_type.en'),
			value: Language.EN,
		},
		{
			label: t('shared.language_type.other'),
			value: Language.OTHER,
		},
	]
})

export const BELGIAN_LANGUAGE_OPTIONS = computed<DataItem<Language>[]>(() => {
	return [
		{
			label: i18nPlugin.global.t(`shared.language_type.nl`),
			value: Language.NL,
		},
		{
			label: i18nPlugin.global.t(`shared.language_type.fr`),
			value: Language.FR,
		},
	]
})

export function getLanguageLabel(language: Language | null): string | null {
	return LANGUAGE_OPTIONS.value.find((item) => item.value === language)?.label || null
}
