<script lang="ts" setup>
import { ref } from 'vue'

import AppIcon from '@/components/app/icon/AppIcon.vue'
import AppText from '@/components/app/text/AppText.vue'
import { Icon } from '@/models/icon.enum'
import { firstUppercase } from '@/utils/string.util'

const props = defineProps<{
	label: string
	onClick: () => void
}>()

const isClickHeld = ref<boolean>(false)

function onButtonClick(): void {
	props.onClick()
}

function onButtonHold(isHeld: boolean): void {
	isClickHeld.value = isHeld
}
</script>

<template>
	<div class="flex flex-row justify-between gap-4">
		<AppText
			color="white"
			:value="firstUppercase(props.label)"
		/>
		<button
			class="shrink-0"
			@click.stop="onButtonClick"
			@mousedown.stop="onButtonHold(true)"
			@mouseup.stop="onButtonHold(false)"
		>
			<AppIcon
				:color="isClickHeld ? 'lima' : 'white'"
				:icon="Icon.COPY"
				size="md"
			/>
		</button>
	</div>
</template>
