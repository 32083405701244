import { ref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import { useToastMessages } from '@/composables/toastMessages.composable'
import type { Permission } from '@/constants/permission.enum'
import { oAuthClient } from '@/http/oAuthClient'
import { useAuthStore } from '@/modules/authentication/stores/auth.store'
import { i18nPlugin } from '@/plugins/i18n.plugin'
import ROUTE_NAME from '@/router/routeName'
import routes from '@/router/routes'
import { logError } from '@/utils/logger.util'

const { t } = i18nPlugin.global
const toast = useToastMessages()

const hasLoginFailed = ref<boolean>(false)

const router = createRouter({
	history: createWebHistory(),
	routes,
})

function hasPermissions(permissionList: Permission[]): boolean {
	const authStore = useAuthStore()
	return authStore.hasPermission(permissionList)
}

async function checkIfUserHasPermissionForRoute(
	to: RouteLocationNormalized,
	from: RouteLocationNormalized
): Promise<void> {
	const permissionsRequiredForRoute = to.meta.permissions

	if (!permissionsRequiredForRoute) {
		return
	}

	if (hasPermissions(permissionsRequiredForRoute)) {
		return
	}

	toast.pushError(t('auth.no_permission'))

	if (from) {
		await router.push(from)
		return
	}

	await router.push({ name: ROUTE_NAME.DASHBOARD })
}

async function navigateToDashboardIfUserIsAuthenticated(to: RouteLocationNormalized): Promise<void> {
	if (!oAuthClient.isLoggedIn()) {
		return
	}

	if (to.name !== ROUTE_NAME.AUTH.LOGIN) {
		return
	}

	await router.push({ name: ROUTE_NAME.DASHBOARD })
}

async function checkIfUserIsAuthenticatedWhenAuthRequired(to: RouteLocationNormalized): Promise<void> {
	if (!to.meta.requiresAuth) {
		return
	}

	if (oAuthClient.isLoggedIn()) {
		return
	}

	if (to.name !== ROUTE_NAME.DASHBOARD && to.name !== ROUTE_NAME.AUTH.LOGIN) {
		toast.pushError(t('auth.not_authenticated'))
	}

	await oAuthClient.logout()
	await router.push({
		name: ROUTE_NAME.AUTH.LOGIN,
		query: { redirect: to.fullPath },
	})
}

async function fetchUserDataIfTokenExists(): Promise<void> {
	const authStore = useAuthStore()

	if (hasLoginFailed.value) {
		return
	}

	try {
		await authStore.fetchAuthenticatedUserInfo()
	} catch {
		hasLoginFailed.value = true
		await oAuthClient.logout()
		await router.push({ name: ROUTE_NAME.AUTH.LOGIN })
	}
}

router.beforeEach(async (to, from, next) => {
	await fetchUserDataIfTokenExists()
	await navigateToDashboardIfUserIsAuthenticated(to)
	await checkIfUserIsAuthenticatedWhenAuthRequired(to)
	await checkIfUserHasPermissionForRoute(to, from)
	next()
})

router.onError((error: unknown) => {
	logError(`[ROUTER] Error: ${error}`)
})

export default router
