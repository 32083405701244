<script lang="ts" setup>
import { useClipboard } from '@vueuse/core'
import { computed } from 'vue'

import { StyleBuilder } from '@/builders/style.builder'
import AppIcon from '@/components/app/icon/AppIcon.vue'
import AppInfoBubble from '@/components/app/info-bubble/AppInfoBubble.vue'
import AppTooltip from '@/components/app/tooltip/AppTooltip.vue'
import type { InfoBubble } from '@/constants/infoBubble.enum'
import { Icon } from '@/models/icon.enum'
import { i18nPlugin } from '@/plugins/i18n.plugin'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'
import { firstUppercase } from '@/utils/string.util'

export interface AppTextProps {
	value?: number | string | null
	toolTip?: string | null
	hasTruncate?: boolean
	hasTooltip?: boolean
	hasNoWrap?: boolean
	hasLineClamp?: boolean
	break?: 'all' | 'normal' | 'words'
	isHtml?: boolean
	size?: '2xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs'
	fontWeight?: 'bold' | 'light' | 'medium'
	color?: DefaultThemeColor
	uppercaseFirst?: boolean
	infoBubble?: InfoBubble
	canCopy?: boolean
	isStrikeThrough?: boolean
}

const { t } = i18nPlugin.global
const props = defineProps<AppTextProps>()

const clipBoard = useClipboard()

const size = computed<'2xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs'>(() => props.size ?? 'md')
const fontWeight = computed<'bold' | 'light' | 'medium'>(() => props.fontWeight ?? 'medium')
const color = computed<DefaultThemeColor>(() => props.color ?? 'text')

const stylingCass = computed<string>(() => {
	return new StyleBuilder()
		.add('transition-all')
		.add(`font-${fontWeight.value}`)
		.add(`text-${size.value}`)
		.add(`text-${color.value}`)
		.addConditional(Boolean(props.hasTruncate), 'truncate hide-tooltip-safari')
		.addConditional(Boolean(props.hasLineClamp), 'line-clamp-1')
		.addConditional(props.break === 'all', 'break-all')
		.addConditional(props.break === 'words', 'break-words')
		.addConditional(props.break === 'normal', 'break-normal')
		.addTertiary(props.hasNoWrap, 'whitespace-nowrap', 'whitespace-normal')
		.addTertiary(props.isStrikeThrough, 'line-through', 'no-underline')
		.build()
})

const text = computed<string>(() => {
	let result = props.value?.toString() || ''

	if (Boolean(props.isHtml)) {
		const parser = new DOMParser()
		const htmlDoc = parser.parseFromString(result, 'text/html')
		result = htmlDoc.body.textContent || ''
	}

	if (Boolean(props.uppercaseFirst)) {
		result = firstUppercase(result)
	}

	if (result === '') {
		result = '-'
	}

	return result
})

const infoBubbleIconSize = computed<'lg' | 'md' | 'sm'>(() => {
	if (size.value === '2xl' || size.value === 'xl' || size.value === 'lg') {
		return 'lg'
	}

	if (size.value === 'md' || size.value === 'sm') {
		return 'md'
	}

	return 'sm'
})

function onCopyButtonClick(): void {
	if (props.canCopy) {
		clipBoard.copy(text.value)
	}
}
</script>

<template>
	<p
		v-if="!infoBubble && !props.canCopy"
		:class="stylingCass"
	>
		<AppTooltip
			as-child
			:delay-duration="300"
			:is-disabled="!props.hasTooltip"
			max-width="480px"
			:value="props.toolTip?.toString() ?? props.value?.toString()"
		>
			<span v-html="text" />
		</AppTooltip>
	</p>
	<div
		v-else
		class="flex flex-row items-center gap-2"
	>
		<p
			:class="stylingCass"
			v-html="text"
		/>
		<AppTooltip
			:is-disabled="!clipBoard.copied.value"
			:value="`${t('shared.item_copied_to_clipboard', { item: props.value })}!`"
		>
			<AppIcon
				v-if="props.canCopy && props.value"
				class="cursor-pointer"
				:color="clipBoard.copied.value ? 'lavender-indigo' : 'lima'"
				:icon="Icon.COPY"
				size="sm"
				@click.stop="onCopyButtonClick"
			/>
		</AppTooltip>
		<AppInfoBubble
			v-if="props.infoBubble && props.value"
			:info-bubble="infoBubble"
			:size="infoBubbleIconSize"
		/>
	</div>
</template>

<style scoped>
.hide-tooltip-safari::after {
	content: '';
	display: block;
}
</style>
