import type { VueQueryPluginOptions } from '@tanstack/vue-query'
export { VueQueryPlugin } from '@tanstack/vue-query'

export const vueQueryPluginOptions: VueQueryPluginOptions = {
	queryClientConfig: {
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				retry: false,
			},
		},
	},
}
