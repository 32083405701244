<script setup lang="ts">
import { TooltipArrow, TooltipContent, TooltipPortal, TooltipProvider, TooltipRoot, TooltipTrigger } from 'radix-vue'
import { useSlots } from 'vue'

import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

const props = withDefaults(
	defineProps<{
		value?: string | null
		isDisabled?: boolean
		side?: 'bottom' | 'left' | 'right' | 'top'
		delayDuration?: number
		maxWidth?: string
		color?: DefaultThemeColor
		asChild?: boolean
		disableHoverableContent?: boolean
	}>(),
	{
		isDisabled: false,
		side: 'top',
		disableHoverableContent: false,
		color: 'lavender-indigo',
		value: null,
		asChild: false,
		maxWidth: undefined,
		delayDuration: 0,
	}
)

const slots = useSlots()
</script>

<template>
	<TooltipProvider :delay-duration="props.delayDuration">
		<TooltipRoot
			disable-closing-trigger
			:disable-hoverable-content="props.disableHoverableContent"
		>
			<TooltipTrigger :as-child="props.asChild">
				<slot />
			</TooltipTrigger>
			<TooltipPortal v-if="(props.value && !props.isDisabled) || slots['custom-content']">
				<TooltipContent
					class="z-[9999] rounded-lg px-4 py-2 text-white will-change-[transform,opacity]"
					:class="[
						'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade',
						'data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade',
						'data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade',
						'data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade',
						`bg-${props.color}`,
					]"
					:side="props.side"
					:side-offset="5"
					:style="{ maxWidth: props.maxWidth }"
				>
					<p v-html="props.value" />
					<slot name="custom-content" />
					<TooltipArrow
						:class="`fill-${props.color}`"
						size="8"
					/>
				</TooltipContent>
			</TooltipPortal>
		</TooltipRoot>
	</TooltipProvider>
</template>
