import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const referralPremiumRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.REFERRAL_PREMIUM_READ]),
		component: async () => import('@/modules/application-premium/view/ReferralPremiumsView.vue'),
		name: ROUTE_NAME.REFERRAL_PREMIUM.INDEX,
		path: '/referral-premiums',
	},
	{
		beforeEnter: permissionMiddleware([Permission.REFERRAL_PREMIUM_READ]),
		component: async () => import('@/modules/application-premium/view/ReferralPremiumDetailView.vue'),
		name: ROUTE_NAME.REFERRAL_PREMIUM.DETAIL,
		path: '/referral-premiums/:uuid',
	},
]
