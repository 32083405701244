export class StyleBuilder {
	private styles: string[] = []

	add(style: string): StyleBuilder {
		this.styles.push(style)
		return this
	}

	addConditional(condition: boolean, style: string): StyleBuilder {
		if (condition) {
			this.add(style)
		}

		return this
	}

	addTertiary(condition: boolean, trueStyle: string, falseStyle: string): StyleBuilder {
		if (condition) {
			this.add(trueStyle)
		} else {
			this.add(falseStyle)
		}

		return this
	}

	build(): string {
		return this.styles.join(' ')
	}
}
