import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const dashboardRoutes: RouteRecordRaw[] = [
	{
		component: () => import('@/modules/dashboard/views/DashboardView.vue'),
		name: ROUTE_NAME.DASHBOARD,
		path: '/dashboard',
	},
]
