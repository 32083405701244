import * as Sentry from '@sentry/vue'
import { useLocalStorage } from '@vueuse/core'
import type { App } from 'vue'

import { EnvironmentMode } from '@/composables/environment.composable'
import { CURRENT_ENVIRONMENT, SENTRY_DSN } from '@/constants/environment.constant'

export function useSentryIntegration(app: App<Element>): void {
	if (SENTRY_DSN === undefined) {
		return
	}

	if (CURRENT_ENVIRONMENT === EnvironmentMode.DEVELOPMENT) {
		return
	}

	const importErrorOccurrences = useLocalStorage<number>('importErrorOccurrences', 0)

	Sentry.init({
		app: app,
		dsn: SENTRY_DSN,
		environment: CURRENT_ENVIRONMENT,
		release: BUILD_NUMBER,
		integrations: [],
		tracesSampleRate: 0.1,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1,
		sampleRate: 0.1,
		attachStacktrace: true,
		beforeSend(event, hint) {
			const originalException = hint.originalException as Error

			if (
				importErrorOccurrences.value < 3 &&
				originalException &&
				originalException.message &&
				(originalException.message.includes('Failed to fetch dynamically imported module') ||
					originalException.message.includes('Importing a module script failed'))
			) {
				importErrorOccurrences.value++
				window.location.reload()
				return null
			}

			importErrorOccurrences.value = 0

			return event
		},
	})
}
