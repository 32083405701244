import { Language } from '@/constants/language.enum.ts'
import { Permission } from '@/constants/permission.enum.ts'
import { StaffType } from '@/constants/staffType.enum.ts'
import type { User } from '@/models/user.type.ts'
import { UuidUtil } from '@/utils/uuid.util.ts'

export class UserBuilder {
	value: User = {
		uuid: UuidUtil.getRandom<string>(),
		createdAt: new Date(),
		updatedAt: new Date(),
		type: StaffType.STAFF,
		locale: Language.NL,
		staff: {
			email: 'staff@email.com',
			departmentStaff: [],
			firstName: 'First',
			uuid: UuidUtil.getRandom(),
			lastName: 'Last',
			role: {
				name: 'Admin',
				uuid: UuidUtil.getRandom(),
				permissions: [Permission.ADMIN],
			},
		},
	}

	constructor() {}

	build(): User {
		return this.value
	}

	withUuid(uuid: string): UserBuilder {
		this.value.uuid = uuid
		return this
	}

	withCreatedAt(createdAt: Date): UserBuilder {
		this.value.createdAt = createdAt
		return this
	}

	withUpdatedAt(updatedAt: Date): UserBuilder {
		this.value.updatedAt = updatedAt
		return this
	}
}
