import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const AuthenticationRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/authentication/views/AuthLoginView.vue'),
		meta: { requiresAuth: false },
		name: ROUTE_NAME.AUTH.LOGIN,
		path: '/login',
	},
	{
		component: async () => import('@/modules/authentication/views/AuthAdCallbackView.vue'),
		meta: { requiresAuth: false },
		name: ROUTE_NAME.AUTH.AD_CALLBACK,
		path: '/auth-ad',
	},
	{
		component: async () => import('@/modules/authentication/views/AuthLogoutView.vue'),
		meta: { requiresAuth: false },
		name: ROUTE_NAME.AUTH.LOGOUT,
		path: '/logout',
	},
	{
		component: async () => import('@/modules/authentication/views/AuthMaintenanceModeView.vue'),
		meta: { requiresAuth: false },
		name: ROUTE_NAME.AUTH.MAINTENANCE,
		path: '/maintenance',
	},
	{
		component: async () => import('@/modules/authentication/views/AuthNoPermissionView.vue'),
		meta: { requiresAuth: false },
		name: ROUTE_NAME.AUTH.NO_PERMISSION,
		path: '/no-permission',
	},
]
