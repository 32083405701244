import axios from 'axios'

import { API_BASE_URL } from '@/constants/environment.constant'
import { httpClient } from '@/http/httpClient.ts'
import type { User } from '@/models/user.type.ts'

export class AuthenticationService {
	static async checkStatus(): Promise<void> {
		await axios.get(API_BASE_URL)
	}

	static async getUserInfo(): Promise<User> {
		const response = await httpClient.get(`/auth/userinfo`)
		return response.data
	}

	static async getActiveDirectoryLoginUrl(): Promise<string> {
		const response = await httpClient.get('/auth/ad/signin')

		return response.data.url
	}

	static async getActiveDirectorySignOutUrl(): Promise<string> {
		const response = await httpClient.get('/auth/ad/signout')

		return response.data.url
	}
}
