import { useToast } from 'vue-toastification'
import type { ToastID } from 'vue-toastification/dist/types/types'

import AppToast from '@/components/toast/AppToast.vue'
import ToastContent from '@/components/toast/ToastContent.vue'
import type { ValidationError } from '@/composables/validation.composable'
import { getApiErrorLabel } from '@/constants/apiError.enum'
import type { AxiosApiError } from '@/http/httpClient'
import { i18nPlugin } from '@/plugins/i18n.plugin'
import { decodeFromBase64 } from '@/utils/encoding.util'
import { firstUppercase } from '@/utils/string.util'

const VALIDATION_ERROR_CODE = 'validation_error'
const HTTP_STATUS_FORBIDDEN = 403

export function useToastMessages(): {
	pushSuccess: (message: string, testId?: string) => ToastID
	pushInfo: (message: string) => ToastID
	pushWarning: (message: string) => ToastID
	pushError: (message: string) => ToastID
	pushValidationError: (errors: ValidationError[]) => ToastID
	pushApiError: (error: unknown, fallBackMessage?: string) => ToastID
} {
	const toast = useToast()

	function pushSuccess(message: string, testId?: string): ToastID {
		return toast.success({
			component: AppToast,
			props: {
				label: message,
				testId: testId,
			},
		})
	}

	function pushInfo(message: string): ToastID {
		return toast.info(firstUppercase(message))
	}

	function pushWarning(message: string): ToastID {
		return toast.warning(firstUppercase(message))
	}

	function pushError(
		message: string,
		options?: {
			onClick?: () => void
		}
	): ToastID {
		if (options?.onClick) {
			return toast.error({
				component: ToastContent,
				props: {
					label: message,
					onClick: options.onClick,
				},
			})
		}

		return toast.error(firstUppercase(message))
	}

	function pushValidationError(errors: ValidationError[] | null): ToastID {
		const errorMessage = errors?.reverse()?.[0]?.message
		const defaultErrorMessage = i18nPlugin.global.t('shared.toast.validation_error')
		return pushError(errorMessage ?? defaultErrorMessage)
	}

	function formatErrorToString(error: unknown): string {
		const axiosError = error as AxiosApiError

		const paginationQuery = axiosError?.config?.url?.split('?q=')?.[1] ?? 0
		const paginationOptions = paginationQuery ? decodeFromBase64(paginationQuery) : undefined

		return JSON.stringify(
			{
				error: {
					api: `${axiosError?.config?.baseURL}${axiosError?.config?.url}`,
					url: window.location.toString(),
					method: axiosError?.config?.method,
					paginationOptions: paginationOptions,
					status: axiosError?.response?.status,
					errors: 'id' in axiosError.response.data ? axiosError?.response?.data : axiosError?.response?.data?.errors,
					payload: axiosError?.config?.data ? JSON.parse(axiosError?.config?.data) : undefined,
				},
			},
			null,
			2
		)
	}

	function onToastClick(error: AxiosApiError): void {
		navigator.clipboard.writeText(formatErrorToString(error)).then()
	}

	function pushApiError(error: unknown, fallBackMessage?: string): ToastID {
		const axiosApiError = error as AxiosApiError

		if (
			!axiosApiError.response ||
			!axiosApiError.response.data ||
			(!Array.isArray(axiosApiError.response.data) && typeof axiosApiError.response.data !== 'object')
		) {
			return pushError(error ? (error as string) : i18nPlugin.global.t('shared.toast.error'))
		}

		if (axiosApiError.response?.status === HTTP_STATUS_FORBIDDEN) {
			return pushError(i18nPlugin.global.t('shared.toast.no_permission'))
		}

		const errorResponseData = axiosApiError.response.data

		function toastFn(): void {
			onToastClick(axiosApiError)
		}

		if ('id' in errorResponseData) {
			return pushError(`${errorResponseData.detail}`, { onClick: toastFn })
		}

		const firstError = errorResponseData?.errors?.[0]
		const code = firstError?.code ?? null
		const errorDetails = firstError?.detail ?? null

		const defaultErrorMessage = fallBackMessage ?? errorDetails ?? i18nPlugin.global.t('shared.toast.error')

		if (!code) {
			return pushError(defaultErrorMessage)
		}

		if (code === VALIDATION_ERROR_CODE) {
			return pushError(`${defaultErrorMessage} (${code})`, {
				onClick: toastFn,
			})
		}

		const apiErrorLabel = getApiErrorLabel(code)

		if (!apiErrorLabel) {
			return pushError(`${defaultErrorMessage} (${code})`, {
				onClick: toastFn,
			})
		}

		return pushError(apiErrorLabel, { onClick: toastFn })
	}

	return {
		pushApiError,
		pushError,
		pushInfo,
		pushSuccess,
		pushValidationError,
		pushWarning,
	}
}
