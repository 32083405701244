import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const anomaliesRoute: RouteRecordRaw[] = [
	{
		path: '/anomalies',
		name: ROUTE_NAME.ANOMALIES.INDEX,
		beforeEnter: permissionMiddleware([Permission.ANOMALY_READ]),
		component: async () => import('@/modules/anomalies/views/AnomaliesView.vue'),
		redirect: { name: ROUTE_NAME.ANOMALIES.SHORTAGES.INDEX },
		children: [
			{
				path: 'excess',
				name: ROUTE_NAME.ANOMALIES.EXCESS.INDEX,
				component: async () => import('@/modules/anomalies/views/AnomaliesExcessView.vue'),
			},
			{
				path: 'shortages',
				name: ROUTE_NAME.ANOMALIES.SHORTAGES.INDEX,
				component: async () => import('@/modules/anomalies/views/AnomaliesShortagesView.vue'),
			},
			{
				path: 'to-validate',
				name: ROUTE_NAME.ANOMALIES.TO_VALIDATE.INDEX,
				component: async () => import('@/modules/anomalies/views/AnomaliesToValidateView.vue'),
			},
		],
	},
	{
		path: '/anomalies/:employeeUuid',
		name: ROUTE_NAME.ANOMALIES.DETAIL.INDEX,
		beforeEnter: permissionMiddleware([Permission.ANOMALY_READ]),
		component: async () => import('@/modules/anomalies/views/detail/AnomaliesDetailView.vue'),
	},
]
