import type { DefaultError, PlaceholderDataFunction } from '@tanstack/vue-query'
import { type QueryKey, useQuery } from '@tanstack/vue-query'
import type { ComputedRef, MaybeRef, MaybeRefOrGetter } from 'vue'
import { computed, nextTick } from 'vue'

import { useToastMessages } from '@/composables/toastMessages.composable'
import { type PaginatedItem } from '@/models/axios/axiosPromisePaginated.type'

export interface QueryPaginatedResult<T> {
	onLoaded: (callback: (data: PaginatedItem<T>) => void) => void
	data: ComputedRef<PaginatedItem<T>>
	isLoading: ComputedRef<boolean>
	isLoadingInitial: ComputedRef<boolean>
	refetch: () => Promise<void>
}

export interface QueryResult<T> {
	onLoaded: (callback: (data: T) => void) => void
	data: ComputedRef<T>
	isLoading: ComputedRef<boolean>
	isLoadingInitial: ComputedRef<boolean>
	refetch: () => Promise<void>
}

export const EMPTY_PAGINATED_ITEM: PaginatedItem<never> = {
	items: [],
	total: 0,
}

export const DEFAULT_STALE_TIME = 1000 * 60 * 8 // 8 minutes

export type QueryOnLoaded<T> = {
	triggerCallback: (data: T) => void
	onLoaded: (callback: (data: T) => void) => void
}

export function useQueryOnLoaded<T>(): QueryOnLoaded<T> {
	let callbackFunction: ((data: T) => void) | null

	function onLoaded(callback: (data: T) => void): void {
		callbackFunction = callback
	}

	function triggerCallback(data: T): void {
		nextTick(() => {
			if (callbackFunction) {
				callbackFunction(data)
			}
		}).then()
	}

	return {
		triggerCallback,
		onLoaded,
	}
}

// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunctionGuard<T> = T extends Function ? never : T
type PlaceHolderData<
	TQueryData,
	TError = DefaultError,
	TQueryKey extends QueryKey = QueryKey,
> = PlaceholderDataFunction<NonFunctionGuard<TQueryData>, TError, NonFunctionGuard<TQueryData>, TQueryKey>

export function useQueryResult<T>(options: {
	queryKey: MaybeRef<QueryKey>
	queryFn: () => Promise<T> | null
	staleTime?: number
	placeholderData?: PlaceHolderData<T>
	keepPreviousData?: boolean
	enabled?: MaybeRefOrGetter<boolean | undefined>
}): QueryResult<T | null> {
	const { onLoaded, triggerCallback } = useQueryOnLoaded<T | null>()
	const toast = useToastMessages()

	const query = useQuery<T | null>({
		enabled: options.enabled,
		queryKey: options.queryKey,
		staleTime: options.staleTime,
		placeholderData: (data) => {
			if (options.keepPreviousData !== undefined && options.placeholderData !== undefined) {
				throw new Error('Cannot use both keepPreviousData and placeholderData')
			}

			if (options.keepPreviousData === true) {
				return data
			}

			if (options.placeholderData !== undefined) {
				return options.placeholderData(data ?? undefined, undefined)
			}

			return undefined
		},
		queryFn: async () => {
			try {
				const response = await options.queryFn()
				triggerCallback(response)
				return response
			} catch (error) {
				toast.pushApiError(error)
				return null
			}
		},
	})

	return {
		onLoaded,
		isLoading: computed<boolean>(() => query.isFetching.value),
		isLoadingInitial: computed<boolean>(() => query.isLoading.value),
		data: computed<T | null>(() => query.data.value ?? null),
		refetch: async (): Promise<void> => {
			await query.refetch()
		},
	}
}

export function useQueryPaginatedResult<T>(options: {
	queryKey: MaybeRef<QueryKey>
	queryFn: () => Promise<PaginatedItem<T>>
	keepPreviousData?: boolean
	staleTime?: number
	enabled?: MaybeRefOrGetter<boolean | undefined>
}): QueryPaginatedResult<T> {
	const { onLoaded, triggerCallback } = useQueryOnLoaded<PaginatedItem<T>>()
	const toast = useToastMessages()

	const query = useQuery<PaginatedItem<T>>({
		enabled: options.enabled,
		queryKey: options.queryKey,
		staleTime: options.staleTime,
		placeholderData: (data) => {
			if (options.keepPreviousData) {
				return data
			}

			return undefined
		},
		queryFn: async () => {
			try {
				const response = await options.queryFn()
				triggerCallback(response)
				return response
			} catch (error) {
				toast.pushApiError(error)
				return EMPTY_PAGINATED_ITEM
			}
		},
	})

	return {
		onLoaded,
		isLoading: computed<boolean>(() => query.isFetching.value),
		isLoadingInitial: computed<boolean>(() => query.isLoading.value),
		data: computed<PaginatedItem<T>>(() => query.data.value ?? EMPTY_PAGINATED_ITEM),
		refetch: async (): Promise<void> => {
			await query.refetch()
		},
	}
}

export function useQueryListResult<T>(options: {
	queryKey: MaybeRef<QueryKey>
	queryFn: () => Promise<T[]>
	keepPreviousData?: boolean
	staleTime?: number
	enabled?: MaybeRefOrGetter<boolean | undefined>
}): QueryResult<T[]> {
	const { onLoaded, triggerCallback } = useQueryOnLoaded<T[]>()
	const toast = useToastMessages()

	const query = useQuery<T[]>({
		enabled: options.enabled,
		queryKey: options.queryKey,
		placeholderData: (data) => {
			if (options.keepPreviousData) {
				return data
			}

			return undefined
		},
		staleTime: options.staleTime,
		queryFn: async () => {
			try {
				const response = await options.queryFn()
				triggerCallback(response)
				return response
			} catch (error) {
				toast.pushApiError(error)
				return []
			}
		},
	})

	return {
		onLoaded,
		isLoading: computed<boolean>(() => query.isFetching.value),
		isLoadingInitial: computed<boolean>(() => query.isLoading.value),
		data: computed<T[]>(() => query.data.value ?? []),
		refetch: async (): Promise<void> => {
			await query.refetch()
		},
	}
}
