<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppIcon from '@/components/app/icon/AppIcon.vue'
import AppTooltip from '@/components/app/tooltip/AppTooltip.vue'
import type { InfoBubble } from '@/constants/infoBubble.enum'
import { Icon } from '@/models/icon.enum'
import type { InfoBubbleItem } from '@/modules/settings/models/info-bubbles/infoBubble.type'
import { useInfoBubbleQuery } from '@/queries/infoBubble.query'

const { t, locale } = useI18n()
const infoBubbleQuery = useInfoBubbleQuery()

const props = defineProps<{
	infoBubble?: InfoBubble
	size?: 'lg' | 'md' | 'sm'
}>()

const currentInfoBubble = computed<InfoBubbleItem | null>(() => {
	if (!props.infoBubble) {
		return null
	}

	const foundInfoBubble = infoBubbleQuery.data.value.find((infoBubble) => infoBubble.code === props.infoBubble)

	return foundInfoBubble ?? null
})

const currentInfoBubbleDescription = computed<string>(() => {
	if (infoBubbleQuery.isLoadingInitial.value) {
		return t('shared.loading_data')
	}

	const notFoundMessage = t('shared.item_not_found', {
		item: t('shared.information').toLowerCase(),
	})
	return currentInfoBubble.value?.description?.[locale.value] || `${notFoundMessage} [${props.infoBubble}]`
})
</script>

<template>
	<AppTooltip
		:delay-duration="300"
		max-width="480px"
		:value="currentInfoBubbleDescription"
	>
		<AppIcon
			color="lavender-indigo"
			:icon="Icon.INFO"
			:size="size ?? 'md'"
		/>
	</AppTooltip>
</template>
