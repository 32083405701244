<script lang="ts" setup>
import AppText from '@/components/app/text/AppText.vue'
import { firstUppercase } from '@/utils/string.util'

const props = defineProps<{
	label: string
	testId?: string
}>()
</script>

<template>
	<div :data-test-id="testId">
		<AppText
			color="white"
			:value="firstUppercase(props.label)"
		/>
	</div>
</template>
