import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { CURRENT_ENVIRONMENT } from '@/constants/environment.constant'

export enum EnvironmentMode {
	DEVELOPMENT = 'development',
	QA = 'qa',
	SANDBOX = 'sandbox',
	PRODUCTION = 'production',
	STAGING = 'staging',
}

interface Environment {
	isDevelopment: ComputedRef<boolean>
	isAllowed: (environmentMode: EnvironmentMode[]) => boolean
	version: ComputedRef<string>
	currentEnvironment: ComputedRef<EnvironmentMode>
}

export function useEnvironment(): Environment {
	const isDevelopment = computed<boolean>(() => {
		return CURRENT_ENVIRONMENT === EnvironmentMode.DEVELOPMENT
	})

	const currentEnvironment = computed<EnvironmentMode>(() => {
		return CURRENT_ENVIRONMENT as EnvironmentMode
	})

	const version = computed<string>(() => {
		return BUILD_NUMBER as string
	})

	function isAllowed(environmentMode: EnvironmentMode[]): boolean {
		if (CURRENT_ENVIRONMENT === undefined) {
			return true
		}

		return environmentMode.includes(CURRENT_ENVIRONMENT as EnvironmentMode)
	}

	return {
		currentEnvironment,
		isAllowed,
		isDevelopment,
		version,
	}
}
