import axios from 'axios'

import { AUTH_BASE_URL, AUTH_CLIENT_ID, CURRENT_ENVIRONMENT } from '@/constants/environment.constant.ts'
import { OAuth2ZitadelClient } from '@/http/zitadel/oAuthZitadelClient.ts'

export const oAuthClient = new OAuth2ZitadelClient({
	axios: axios,
	offline: CURRENT_ENVIRONMENT === 'e2e',
	tokenEndpoint: `${AUTH_BASE_URL}/oauth/v2/token`,
	userInfoEndpoint: `${AUTH_BASE_URL}/oidc/v1/userinfo`,
	authorization: {
		clientId: AUTH_CLIENT_ID,
		url: `${AUTH_BASE_URL}/oauth/v2/authorize`,
		logoutUrl: `${AUTH_BASE_URL}/oidc/v1/end_session`,
		postLogoutRedirectUri: `${window.location.origin}/logout`,
		redirectUri: `${window.location.origin}/auth-ad`,
		grantType: 'authorization_code',
		scopes: ['openid', 'profile', 'email', 'offline_access', 'urn:zitadel:iam:org:id:284457840993688250'],
	},
})
