import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const remittanceRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.REMITTANCE_READ]),
		component: async () => import('@/modules/remittance/views/RemittanceIndexView.vue'),
		name: ROUTE_NAME.REMITTANCE.INDEX,
		path: '/remittance',
	},
	{
		beforeEnter: permissionMiddleware([Permission.REMITTANCE_READ]),
		component: async () => import('@/modules/remittance/views/RemittanceDetailView.vue'),
		name: ROUTE_NAME.REMITTANCE.DETAIL,
		path: '/remittance/:uuid',
	},
]
