<script lang="ts" setup>
import { computed } from 'vue'

import { StyleBuilder } from '@/builders/style.builder'
import AppText from '@/components/app/text/AppText.vue'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

export interface AppCardProps {
	title?: string | null
	hasShadow?: boolean
	color?: DefaultThemeColor | null
	borderColor?: DefaultThemeColor | null
	hoverColor?: DefaultThemeColor | null
	isClickable?: boolean
	hasPadding?: boolean
}

const props = defineProps<AppCardProps>()

const appCardStyle = computed<string>(() => {
	return new StyleBuilder()
		.add('w-full p-4 rounded-lg')
		.addConditional(props.hasShadow, 'shadow-lg')
		.addConditional(props.isClickable, 'text-left')
		.addConditional(Boolean(props.hoverColor), `hover:border-${props.hoverColor}`)
		.addTertiary(Boolean(props.color), `bg-${props.color}`, 'bg-white')
		.addConditional(Boolean(props.borderColor), `border border-${props.borderColor}`)
		.build()
})
</script>

<template>
	<component
		:is="isClickable ? 'button' : 'div'"
		:class="appCardStyle"
	>
		<AppText
			v-if="title"
			class="pb-2"
			font-weight="bold"
			size="lg"
			:value="title"
		/>
		<slot />
	</component>
</template>
