import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const customerRoutes: RouteRecordRaw[] = [
	{
		component: () => import('@/modules/customer/views/CustomerView.vue'),
		name: ROUTE_NAME.CUSTOMERS.INDEX,
		path: '/customers',
		beforeEnter: permissionMiddleware([Permission.CUSTOMER_READ]),
	},
	{
		beforeEnter: permissionMiddleware([Permission.CUSTOMER_READ]),
		children: [
			{
				component: () => import('@/modules/customer/views/detail/overview/CustomerDetailOverviewView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.INDEX,
				path: 'overview',
				redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.DASHBOARD },
				children: [
					{
						component: () => import('@/modules/customer/views/detail/overview/CustomerDetailOverviewDashboardView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.DASHBOARD,
						path: 'dashboard',
					},
					{
						component: () => import('@/modules/customer/views/detail/overview/CustomerDetailOverviewLogbooksView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.LOGBOOKS,
						beforeEnter: permissionMiddleware([Permission.LOGBOOK_READ]),
						path: 'logbook',
					},
				],
			},
			{
				component: () => import('@/modules/customer/views/detail/planning/CustomerDetailPlanningView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.INDEX,
				path: 'planning',
				beforeEnter: permissionMiddleware([Permission.PLANNING_READ]),
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.WEEK_PLANNING,
				},
				children: [
					{
						component: () => import('@/modules/customer/views/detail/planning/CustomerDetailPlanningWeekView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.WEEK_PLANNING,
						path: 'week-planning',
					},
					{
						component: () =>
							import('@/modules/customer/views/detail/planning/CustomerDetailPlanningAvailabilitiesView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.AVAILABILITIES,
						path: 'availabilities',
					},
					{
						component: () =>
							import('@/modules/customer/views/detail/planning/CustomerDetailPlanningRegularEmployeesView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.EMPLOYEES,
						path: 'employees',
					},
				],
			},
			{
				component: () => import('@/modules/customer/views/detail/info/CustomerDetailInfoView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.INDEX,
				path: 'details',
				redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.PERSONAL },
				children: [
					{
						component: () =>
							import('@/modules/customer/views/detail/info/work-station/CustomerDetailInfoWorkStationView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.WORK_STATION,
						path: 'work-station',
					},
					{
						component: () => import('@/modules/customer/views/detail/info/personal/CustomerDetailInfoPersonalView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.PERSONAL,
						path: 'personal',
					},
					{
						component: () => import('@/modules/customer/views/detail/info/referral/CustomerDetailInfoReferralView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.REFERRAL,
						path: 'referral',
					},
					{
						component: () => import('@/modules/customer/views/detail/info/lead/CustomerDetailInfoLeadView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.LEAD,
						path: 'lead',
					},
					{
						component: () =>
							import('@/modules/customer/views/detail/info/cancellation/CustomerDetailInfoCancellationView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.CANCELLATION,
						path: 'cancellation',
					},
					{
						component: () =>
							import('@/modules/customer/views/detail/info/rejection/CustomerDetailInfoRejectionView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.REJECTION,
						path: 'rejection',
					},
				],
			},
			{
				component: () => import('@/modules/customer/views/detail/contract/CustomerDetailContractView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.INDEX,
				path: 'contract',
				redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.SUBSCRIPTION },
				children: [
					{
						component: () =>
							import(
								'@/modules/customer/views/detail/contract/subscription/CustomerDetailContractSubscriptionView.vue'
							),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.SUBSCRIPTION,
						path: 'subscription',
						beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_READ], {
							name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.TERMS_OF_SERVICE,
						}),
					},
					{
						component: () =>
							import(
								'@/modules/customer/views/detail/contract/terms-of-service/CustomerDetailContractTermsOfServiceView.vue'
							),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.TERMS_OF_SERVICE,
						path: 'terms-of-service',
					},
				],
			},
			{
				component: () => import('@/modules/customer/views/detail/absences/CustomerDetailAbsencesView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.ABSENCES.INDEX,
				path: 'absence',
			},
			{
				component: () => import('@/modules/customer/views/detail/refused-match/CustomerDetailRefusedMatchesView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.REFUSED_MATCHES.INDEX,
				path: 'refused-matches',
			},
			{
				component: () => import('@/modules/customer/views/detail/documents/CustomerDetailDocumentsView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.DOCUMENTS.INDEX,
				path: 'documents',
			},
			{
				component: () => import('@/modules/customer/views/detail/notifications/CustomerDetailNotificationsView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.INDEX,
				path: 'notification',
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
				},
				children: [
					{
						component: () =>
							import('@/modules/customer/views/detail/notifications/CustomerDetailNotificationsClaimsView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
						path: 'damage-claims',
					},
					{
						component: () =>
							import('@/modules/customer/views/detail/notifications/CustomerDetailNotificationsAccidentsView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.WORK_ACCIDENTS,
						path: 'work-accidents',
					},
				],
			},
			{
				component: () => import('@/modules/customer/views/detail/service-checks/CustomerDetailServiceChecksView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.INDEX,
				path: 'service-checks',
				beforeEnter: permissionMiddleware([Permission.CHEQUE_READ]),
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.SHORTAGES,
				},
				children: [
					{
						component: () =>
							import(
								'@/modules/customer/views/detail/service-checks/shortages/CustomerDetailServiceChecksShortagesView.vue'
							),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.SHORTAGES,
						path: 'shortages',
					},
					{
						component: () => import('@/views/UnderConstructionView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.CHECKS,
						path: 'cheques',
					},
				],
			},
			{
				component: () => import('@/modules/customer/views/detail/todo/CustomerDetailTodoView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.INDEX,
				path: 'to-dos',
				beforeEnter: permissionMiddleware([Permission.TODO_READ]),
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.OPEN,
				},
				children: [
					{
						component: () => import('@/modules/customer/views/detail/todo/CustomerDetailTodoOpenView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.OPEN,
						path: 'open',
					},
					{
						component: () => import('@/modules/customer/views/detail/todo/CustomerDetailTodoCompletedView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.COMPLETED,
						path: 'completed',
					},
				],
			},
		],
		component: () => import('@/modules/customer/views/detail/CustomerDetailView.vue'),
		name: ROUTE_NAME.CUSTOMERS.DETAIL.INDEX,
		path: '/customers/:uuid',
		redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.INDEX },
	},
	{
		beforeEnter: permissionMiddleware([Permission.CUSTOMER_READ]),
		component: () => import('@/modules/customer/views/prospect/CustomerProspectView.vue'),
		name: ROUTE_NAME.CUSTOMERS.PROSPECT.INDEX,
		path: '/customers/:uuid/prospect',
	},
]
