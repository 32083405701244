import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const todoRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/todo/views/TodoView.vue'),
		name: ROUTE_NAME.TODO.INDEX,
		path: '/todos',
		redirect: { name: ROUTE_NAME.TODO.OPEN },
		beforeEnter: permissionMiddleware([Permission.TODO_READ]),
		children: [
			{
				component: async () => import('@/modules/todo/views/TodoOpenView.vue'),
				name: ROUTE_NAME.TODO.OPEN,
				path: 'open',
			},
			{
				component: async () => import('@/modules/todo/views/TodoCompletedView.vue'),
				name: ROUTE_NAME.TODO.CLOSED,
				path: 'closed',
			},
			{
				component: async () => import('@/modules/todo/views/TodoFollowUpView.vue'),
				name: ROUTE_NAME.TODO.FOLLOW_UP,
				path: 'follow-up',
			},
		],
	},
]
