import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const subsidiesRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.SUBSIDY_READ]),
		component: () => import('@/modules/subsidies/views/SubsidiesView.vue'),
		name: ROUTE_NAME.SUBSIDIES.INDEX,
		path: '/subsidies',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SUBSIDY_READ]),
		component: () => import('@/modules/subsidies/views/SubsidiesDetailView.vue'),
		name: ROUTE_NAME.SUBSIDIES.DETAIL,
		path: '/subsidies/:uuid',
	},
]
