import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const newsRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.NEWS_READ]),
		component: async () => import('@/modules/news/views/NewsIndexView.vue'),
		name: ROUTE_NAME.NEWS.INDEX,
		path: 'news',
	},
	{
		beforeEnter: permissionMiddleware([Permission.NEWS_READ]),
		component: async () => import('@/modules/news/views/NewsDetailView.vue'),
		name: ROUTE_NAME.NEWS.DETAIL,
		path: 'news/:uuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.NEWS_UPDATE]),
		component: async () => import('@/modules/news/views/NewsFormView.vue'),
		name: ROUTE_NAME.NEWS.EDIT,
		path: 'news/edit/:uuid?',
	},
]
