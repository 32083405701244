<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { StyleBuilder } from '@/builders/style.builder'

const props = defineProps({
	justify: {
		default: 'end',
		required: false,
		type: String as PropType<'between' | 'center' | 'end' | 'start'>,
	},
})
const componentStyle = computed<string>(() => {
	return new StyleBuilder()
		.addConditional(props.justify === 'between', 'justify-between')
		.addConditional(props.justify === 'center', 'justify-center')
		.addConditional(props.justify === 'end', 'justify-end')
		.addConditional(props.justify === 'start', 'justify-start')
		.build()
})
</script>

<template>
	<section
		class="flex items-center gap-2"
		:class="componentStyle"
	>
		<slot />
	</section>
</template>
