import { useRegisterSW } from 'virtual:pwa-register/vue'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { logError, logInfo } from '@/utils/logger.util'

interface RefreshServiceWorker {
	needRefresh: ComputedRef<boolean>
	refresh: () => Promise<void>
	cancel: () => void
}

const ONE_MINUTE_INTERVAL = 60 * 1000
const REFRESH_FALLBACK_INTERVAL = 3 * 1000

export function useRefreshServiceWorker(): RefreshServiceWorker {
	async function refresh(): Promise<void> {
		await updateServiceWorker(true)

		setInterval(() => {
			window.location.reload()
		}, REFRESH_FALLBACK_INTERVAL)
	}

	function cancel(): void {
		needRefresh.value = false
	}

	const { needRefresh, updateServiceWorker } = useRegisterSW({
		immediate: true,
		async onRegisteredSW(swScriptUrl: string, registration) {
			logInfo(`ServiceWorker registered at: ${swScriptUrl}`)

			if (!registration) {
				logInfo('No service worker registered')
				return
			}

			setInterval(async () => {
				try {
					await registration.update()
				} catch {
					logError('Failed to update service worker')
				}
			}, ONE_MINUTE_INTERVAL)
		},
	})

	return {
		needRefresh: computed<boolean>(() => needRefresh.value),
		cancel,
		refresh,
	}
}
