function buildFormData(formData: FormData, data: Record<string, never>, parentKey?: string): void {
	if (data !== null && data !== undefined && typeof data === 'object' && !(data instanceof File)) {
		Object.keys(data).forEach((key: string) => {
			buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
		})
	} else {
		if (data && parentKey) {
			formData.append(parentKey, data)
		}
	}
}

export function toFormData(value: Record<string, never>): FormData {
	const formData = new FormData()
	buildFormData(formData, value)
	return formData
}

export function encodeQueryData(data: Record<string, string>): string {
	const ret = []

	for (const d in data) {
		ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
	}

	return ret.join('&')
}

export function encodeToBase64(value: unknown | null): string | null {
	if (!value) {
		return null
	}

	return btoa(JSON.stringify(value))
}

export function decodeFromBase64<T>(value: string | null): T | null {
	if (!value) {
		return null
	}

	return JSON.parse(atob(value))
}
