import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const logbooksRoutes: RouteRecordRaw[] = [
	{
		component: async () => import('@/modules/logbooks/views/LogbooksView.vue'),
		name: ROUTE_NAME.LOGBOOKS.INDEX,
		path: '/logbooks',
		beforeEnter: permissionMiddleware([Permission.LOGBOOK_READ]),
	},
]
