export const TEST_ID = {
	GENERAL: {
		LOADING_BRUSH: 'general_loading_brush',
	},
	AUTH: {
		ADMIN_LOGIN_BUTTON: 'auth_admin_login_button',
		AD_LOGIN_BUTTON: 'auth_ad_login_button',
	},
	NAV_BAR: {
		GREETING_MESSAGE: 'nav_bar_greeting_message',
	},
	SETTINGS: {
		OFFICES: {
			CREATE_BUTTON: 'settings_offices_create_button',
			LANGUAGE_SELECT: 'settings_offices_language_select',
			LIST: 'settings_offices_list',
			NAME_INPUT: 'settings_offices_name_input',
			SAVE_BUTTON: 'settings_offices_save_button',
			STREET_NAME_INPUT: 'settings_offices_street_name_input',
		},
		ORIGIN_COMPANY: {
			NAME_INPUT: 'settings_origin_company_name_input',
			TOOKOVERON_INPUT: 'settings_origin_company_tookoveron_input',
			PAYROLLUUID_INPUT: 'settings_origin_company_payrolluuid_input',
			TERMSOFSERVICESENTON_INPUT: 'settings_origin_company_termsofservicesenton_input',
			STARTINVOICEPERIODON_INPUT: 'settings_origin_company_startinvoiceperiodon_input',
			STARTSENDINGOUTINVOICESON_INPUT: 'settings_origin_company_startsendingoutinvoiceson_input',
		},
	},
	SHARED: {
		ADDRESS: {
			BUS_INPUT: 'shared_address_street_bus_input',
			CITY_INPUT: 'shared_address_city_input',
			COUNTRY_SELECT: 'shared_address_country_select',
			NUMBER_INPUT: 'shared_address_street_number_input',
			POSTAL_CODE_INPUT: 'shared_address_postal_code_input',
			REGION_SELECT: 'shared_address_region_select',
			STREET_INPUT: 'shared_address_street_name_input',
			REMARKS_INPUT: 'shared_address_remarks_input',
			MAP: 'shared_address_map',
		},
		PHONE_NUMBER: {
			LABEL_SELECT: 'shared_phone_number_label_select',
		},
	},
	CUSTOMER: {
		CREATE: {
			CREATE_BUTTON: 'customer_create_button',
			MODAL: 'customer_create_modal',
			DEPARTMENT_SELECT: 'customer_create_department_select',
			FIRST_NAME_INPUT: 'customer_create_first_name_input',
			LAST_NAME_INPUT: 'customer_create_last_name_input',
			SUBMIT_BUTTON: 'customer_create_submit_button',
			SUCCESS_MESSAGE: 'customer_create_success_message',
		},
		OVERVIEW: {
			CUSTOMER_PAGE: 'customer-overview-page',
		},
		PROSPECT: {
			PAGE: { PROSPECT_PAGE: 'customer-prospect-view-page' },
			PERSONAL_STEP: {
				GENERAL_ELEMENT: {
					SELECT_DEPARTMENT: 'customer-prospect-personal-step-form-select-department',
				},
				IDENTITY_ELEMENT: {
					INPUT_FIRST_NAME: 'customer-prospect-identity-step-form-input-first-name',
					INPUT_LAST_NAME: 'customer-prospect-identity-step-form-input-last-name',
					CALL_NAME: 'customer-prospect-identity-step-form-call-name',
					SELECT_GENDER: 'customer-prospect-identity-step-form-select-gender',
					INPUT_BIRTHDAY: 'customer-prospect-identity-step-identity-step-form-input-birthday',
					SELECT_LANGUAGE: 'customer-prospect-identity-step-form-select-language',
				},
				ADDRESS_ELEMENT: {
					ADDRESS_BUTTON_CLICK: 'customer-prospect-address-element-domicile-address-button-click',
					ADDRESS_MODAL: 'customer-prospect-address-element-domicile-address-modal',
					ADDRESS_FORM_SEARCH_SELECT: 'customer-prospect-address-element-domicile-address-form-search-select',
					ADDRESS_MANUAL_INPUT_CHECKBOX: 'customer-prospect-address-element-domicile-address-manual-input-checkbox',
					ADDRESS_INPUT_DATE: 'customer-prospect-address-element-domicile-address-input-date',
					ADDRESS_SAVE_BUTTON: 'customer-prospect-address-element-domicile-address-save-button',
				},
				CONTACT_ELEMENT: {
					PHONE_NUMBER_INPUT: 'customer-prospect-personal-step-contact-element-phone-number-input',
					PHONE_SELECT: 'customer-prospect-personal-step-contact-element',
				},
				PAYMENT_IDENTITIES_ELEMENT: {},
				PETS_ELEMENT: {
					PETS_CHECKBOX_GROUP: 'customer-prospect-pets-element-form-pets-checkbox-group',
					PETS_TEXT_INPUT: 'customer-prospect-pets-element-pets-text-input',
				},
			},
			ACTIVATION_STEP: {
				DESIRED_HOURS_STEP: {
					FREQUENCY_FORM_SELECT: 'customer-prospect-activation-frequency-form-select',
					FORM_INPUT_DAYS: 'customer-prospect-activation-form-input-days',
					FORM_INPUT_HOURS: 'customer-prospect-activation-form-input-hours',
					FORM_INPUT_REMARKS: 'customer-prospect-activation-form-input-remarks',
					FORM_INPUT_REPLACEMENT: 'customer-prospect-activation-form-input-replacement',
				},
				AVAILABILITY_STEP: {},
				TERMS_OF_SERVICE_STEP: {},
				ACTIVATION_STEP: {
					CHECKBOX_GROUP: 'customer-prospect-activation-step-activation-step-form-checkbox-group',
				},
			},
			REFERRAL_STEP: {},
			LEAD_STEP: {},
			FINANCIAL_STEP: {},
			UPDATE_COMPLETE_STEP: {},

			NEXT_STEP_BUTTON: 'customer-prospect-next-step-button',
			PREVIOUS_STEP_BUTTON: 'customer-prospect-previous-step-button',
			SUBMIT_BUTTON: 'customer-prospect-submit-button',
			SUCCESS_MESSAGE: 'customer-prospect-success-message',
		},
	},
	ACCIDENTS: {
		OVERVIEW: {
			ACCIDENTS_PAGE: 'accidents-overview-page',
		},
		CREATE: {
			CREATE_BUTTON: 'accidents-overview-create-button',
			CREATE_FORM_MODAL: 'accidents-create-form-modal',
			EMPLOYEE_AUTO_COMPLETE: 'accident-form-employee-auto-complete',
			CUSTOMER_AUTO_COMPLETE: 'accident-form-customer-auto-complete',
			DATE_INPUT: 'accident-form-date-input',
			TEXT_INPUT: 'accident-form-text-input',
			RADIO_BUTTON_SELECT: 'accident-form-radio-button-select',
			SUBMIT_BUTTON: 'accident-form-submit-button',
		},
	},
	EMPLOYEES: {
		INDEX: {
			CREATE_BUTTON: 'employees_index_create_button',
		},
		CREATE: {
			DEPARTMENT_SELECT: 'employees_create_department_select',
			FIRST_NAME_INPUT: 'employees_create_first_name_input',
			LAST_NAME_INPUT: 'employees_create_last_name_input',
			ID_NUMBER_INPUT: 'employees_create_id_number_input',
			SUBMIT_BUTTON: 'employees_create_submit_button',
			SUCCESS_MESSAGE: 'employees_create_success_message',
		},
		APPLICATION: {
			PAGE: 'employees_application_page',
			PREVIOUS_STEP_BUTTON: 'employees_application_previous_step_button',
			NEXT_STEP_BUTTON: 'employees_application_next_step_button',
			SUBMIT_BUTTON: 'employees_application_submit_button',
			EMPLOYEE_SELECT: 'employees_application_employee_select',
			SUCCESS_MESSAGE: 'employees_application_success_message',
		},
		COMPENSATION: {
			WAGES: {
				CREATE_BUTTON: 'employees_compensation_wages_create_button',
				DATE_INPUT: 'employees_compensation_wages_date_input',
				WAGE_INPUT: 'employees_compensation_wages_wage_input',
				REMARKS_INPUT: 'employees_compensation_wages_remarks_input',
				SUBMIT_BUTTON: 'employees_compensation_wages_submit_button',
				SUCCESS_MESSAGE: 'employees_compensation_wages_success_message',
			},
		},
	},
	CLAIMS: {
		OVERVIEW: {
			CLAIMS_PAGE: 'claims-overview-page',
			CREATE_BUTTON: 'claims-overview-create-button',
		},
		CREATE: {
			EMPLOYEE_AUTO_COMPLETE: 'claims-create-employee-auto-complete',
			CUSTOMER_AUTO_COMPLETE: 'claims-create-customer-auto-complete',
			DATE_INPUT: 'claims-create-date-input',
			DESCRIPTION_INPUT: 'claims-create-description-input',
			SUBMIT_BUTTON: 'claims-create-submit-button',
			SUCCESS_MESSAGE: 'claims-create-success-message',
		},
	},
	TODO: {
		OPEN: {
			OPEN_TODO_PAGE: 'open-todo-page',
		},
		GENERAL: {
			ACTION_MENU_BUTTON: 'todo-general-action-menu-button',
			ITEM: 'todo-general-item',
		},
		CREATE: {
			CREATE_BUTTON: 'todo-create-button',
			CREATE_FORM_MODAL: 'todo-create-form-modal',
			DEPARTMENT_SELECT: 'todo-create-department-select',
			ASSIGNED_STAFF_AUTO_COMPLETE: 'todo-create-assigned-staff-select',
			EMPLOYEE_AUTO_COMPLETE: 'todo-create-employee-auto-complete',
			CUSTOMER_AUTO_COMPLETE: 'todo-create-customer-auto-complete',
			MAIN_CATEGORY_SELECT: 'todo-create-main-category-select',
			DUE_DATE_INPUT: 'todo-create-due-date-input',
			DESCRIPTION_INPUT: 'todo-create-description-input',
			SUCCESS_MESSAGE: 'todo-create-success-message',
			SUBMIT_BUTTON: 'todo-create-submit-button',
		},
		DELETE: {
			DELETE_BUTTON: 'todo-delete-button',
			SUCCESS_MESSAGE: 'todo-delete-success-message',
		},
	},
}
